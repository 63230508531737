<template>
    <div class="page-wrap">
      <div class="session-form-hold">
        <base-card>
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            top
            color="primary"
          />
          <v-card-text class="text-center">
            <h2>PNUD Workspace</h2>
            <v-avatar
              size="60"
              class="mb-4"
            >
              <img
                src="@/assets/images/logo.svg"
                alt=""
              >
            </v-avatar>
  
            <h6 class="text--disabled font-weight-medium mb-10">
              Authentification
            </h6>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              action="/"
            >
              <v-text-field
                v-model="email"
                label="Email"
                :rules="emailRules"
                required
              />
  
              <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                name="input-10-2"
                label="Mot de passe"
                :counter="10"
                :rules="nameRules"
                @click:append="show = !show"
              />
              <!-- <v-checkbox
                v-model="checkbox1"
                label="Se rappeler de moi"
              /> -->
              <v-btn
                :disabled="!valid"
                class="mb-4"
                block
                color="primary"
                dark
                submit
                @click="submit"
              >
                <v-icon left>
                  mdi-login
                </v-icon>
                Connexion
              </v-btn>
              <!--            <v-btn-->
              <!--              class="mb-4"-->
              <!--              block-->
              <!--              color="#DB4437"-->
              <!--              dark-->
              <!--              @click="googleSignIn"-->
              <!--            >-->
              <!--              <v-icon left>-->
              <!--                mdi-google-->
              <!--              </v-icon>-->
  
              <!--              Sign In-->
              <!--            </v-btn>-->
              <div class="d-flex justify-around flex-wrap">
                <v-btn
                  text
                  small
                  color="primary"
                  class="mb-2"
                >
                  Mot de passe oublié
                </v-btn>
                <!--                            <v-btn-->
                <!--                                text-->
                <!--                                small-->
                <!--                                color="primary"-->
                <!--                                to="/app/sessions/sign-up-2"-->
                <!--                                >Create New Account</v-btn-->
                <!--                            >-->
              </div>
            </v-form>
          </v-card-text>
        </base-card>
      </div>
    </div>
  </template>
  <script>
    // import firebase from 'firebase/app'
    import { mapGetters, mapActions } from 'vuex'
    export default {
      name: 'Login',
      metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Login',
      },
      data () {
        return {
          valid: true,
          show: false,
          password: '',
          checkbox1: false,
          checkbox2: false,
          email: 'test1@barometre.com',
          loading: false,
          emailRules: [
            (v) => !!v || 'E-mail est requis',
            (v) => /.+@.+\..+/.test(v) || 'E-mail doit être valide',
          ],
          nameRules: [
            (v) => !!v || 'Le mot de passe est requis',
            (v) =>
              (v && v.length <= 10) ||
              'Nom doit avoir  moins de 10 characters',
          ],
        }
      },
      computed: {
        // ...mapGetters(['loggedInUser', 'error']),
        ...mapGetters(['getStatusCompte', 'getStatusUser', 'getUser', 'getTokenUser', 'getMessageUser']),
      },
      watch: {
        // loading (val) {
        //   if (!val) return
        //   setTimeout(() => (this.loading = false), 2000)
        // },
        // loggedInUser (val) {
        //   if (val && val.uid && val.uid.length > 0) {
        //     this.makeToast('succès', 'Vous êtes connecté avec succès')
        //     console.log('Vous êtes connecté avec succès')
        //     this.loading = true
        //     setTimeout(() => {
        //       this.$router.push('/')
        //     }, 500)
        //   }
        // },
        // error (val) {
        //   if (val != null) {
        //     this.makeToast('warning', val.message)
        //     // this.makeToast('warning', val.message)
        //   }
        // },
  
      },
      methods: {
        ...mapActions(['login']),
        // login: function() {
        //   firebase.auth().signInWithEmailAndPassword(this.email, this.ePassword)
        //     .then(
        //       user => {
        //         // console.log(user);
        //         this.loading = true;
        //         this.$router.push('/');
  
        //       },
        //       err => {
  
        //         // alert(err);
  
        //       }
        //     )
        // },
        // formSubmit () {
        //   this.login({ email: this.email, password: this.ePassword })
        // },
        // googleSignIn () {
        //   const provider = new firebase.auth.GoogleAuthProvider()
  
        //   firebase
        //     .auth()
        //     .signInWithPopup(provider)
        //     .then((result) => {
        //       const newUser = { uid: result.user.uid }
        //       localStorage.setItem('userInfo', JSON.stringify(newUser))
        //       this.$router.push('/')
        //     })
        //     .catch((err) => {
        //       alert('Oops' + err.message)
        //     })
        // },
  
        ...mapActions(['login']),
        async submit () {
          this.$refs.form.validate()
          if (this.$refs.form.validate(true)) {
            await this.login({ email: this.email, password: this.password })
          }
          if (this.getStatusUser === 200) {
            localStorage.setItem('token_fw', this.getTokenUser)
            this.$router.push('/dashboard')
          } else if (this.getStatusUser === 401) {
            this.showAlert(this.getMessageUser, 'error')
          }
        },
        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },
      },
    }
  </script>
  <style lang="scss" scoped>
  .page-wrap {
      background-color: #242939 !important;
      display: flex;
      align-items: center;
      padding: 40px 1rem;
      height: 100%;
      min-height: 100vh;
  }
  .session-form-hold {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
  }
  </style>
  